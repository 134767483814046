// Error Messages
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
export const NO_DATA_FOUND = 'No records found for your filter request.';

// API Responses
export const API_SUCCESS_STATUS = '200';
export const API_FAILURE_STATUS = '400';

//Month Filter object
export const monthFilterObject = [
  { month_id: 0, month_name: 'January' },
  { month_id: 1, month_name: 'February' },
  { month_id: 2, month_name: 'March' },
  { month_id: 3, month_name: 'April' },
  { month_id: 4, month_name: 'May' },
  { month_id: 5, month_name: 'June' },
  { month_id: 6, month_name: 'July' },
  { month_id: 7, month_name: 'August' },
  { month_id: 8, month_name: 'September' },
  { month_id: 9, month_name: 'October' },
  { month_id: 10, month_name: 'November' },
  { month_id: 11, month_name: 'December' }
]

//Payload Generator Function
export function payloadGenerator(filterObject: { [key: string]: any }[], additionalObject: Object = {}): { [key: string]: string | number } {
  let payloadObject = {};
  if (filterObject && filterObject.length) {
    filterObject.forEach(filter => {
      if (filter.key && filter.value && !filter.config.ignore_filter) {
        if (filter.type === 'checkbox'){
          payloadObject[filter.key] = filter.config.trueValue;
        }
        else{
          payloadObject[filter.key] = typeof filter.value === 'string' ? filter.value.trim() : filter.value;        }
      }
    });
    Object.keys(additionalObject).forEach(obj => {
      payloadObject[obj] = additionalObject[obj];
    })
  }
  return payloadObject;
}

//Response data sorting function
export function sortResponseData(data: { [key: string]: any }[], key: string, order: string = 'desc', data_type: string): { [key: string]: any }[] {
  if (data && data.length && key) {
    try {
      if (data_type === 'number') {
        if (order === 'desc') {
          return data.sort((a, b) => parseFloat(b[key] == null ? 0 : b[key]) - parseFloat(a[key] == null ? 0 : a[key]));
        } else {
          return data.sort((a, b) => parseFloat(a[key] == null ? 0 : a[key]) - parseFloat(b[key] == null ? 0 : b[key]));
        }
      }
      else if (data_type === 'string') {
        if (order === 'desc') {
          return data.sort((a, b) => {
            const nameA = a[key] ? a[key].toLowerCase().trim() : '';
            const nameB = b[key] ? b[key].toLowerCase().trim() : '';
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
        } else {
          return data.sort((a, b) => {
            const nameA = b[key] ? b[key].toLowerCase().trim() : '';
            const nameB = a[key] ? a[key].toLowerCase().trim() : '';
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
        }
      }
      else if (data_type === 'date') {
        if (order === 'desc') {
          return data.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
        } else {
          return data.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
        }
      }
    } catch (error) {
      console.trace(error);
      return data;
    }
  }
  return data;
}
