import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private httpService: HttpService) { }

  getUserDetails(): Observable<any> {
    return this.httpService.checkEnvironment('getUserDetails', 'GET', null, null);
  }

  getHistoryOfChanges(payload): Observable<any> {
    return this.httpService.checkEnvironment('getHistoryOfChanges', 'POST', payload, null);
  }

  getStateDropDownValues(payload): Observable<any> {
    return this.httpService.checkEnvironment('getStateDropDownValues', 'POST', payload, null);
  }

  getGeographyMap(payload): Observable<any> {
    return this.httpService.checkEnvironment('getGeographyMap', 'POST', payload, null);
  }

  sendDeveloperErrorMail(payload): Observable<any> {
    return this.httpService.checkEnvironment('sendDeveloperErrorMail', 'POST', payload, null);
  }
}
