import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.sass']
})
export class ErrorPageComponent implements OnInit, AfterViewInit {

  @Output() closeEvent = new EventEmitter();
  @ViewChild('popContent') popContent: any;

  private popupModal: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.popupModal = this.modalService.open(this.popContent, { backdrop: 'static', windowClass: 'modal-holder-md', size: 'md', keyboard: false });
      this.popupModal.result.then(() => {
        this.closeEvent.emit(false);
      }, () => {
        this.closeEvent.emit(false);
      });
    });
  }
}
