import { Injectable } from '@angular/core';
import { HttpResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  addCommonHttpOptions(request): HttpRequest<any> {
    try {
      const req = request.clone({
        setHeaders: {
          UserDetails: this.localStorageService.getStorage('userDetails') ? this.localStorageService.getStorage('userDetails') : '',
        }
      });
      req.withCredentials = true;
      return req;
    } catch (error) {
      console.trace(error);
    }
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent |
    HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const custom_request = this.addCommonHttpOptions(req);
    return next.handle(custom_request).pipe(
      catchError(err => {
        if (err.status === 401) {
          const currentLocation = location.href;
          window.location.href = environment.idmLogInUrl
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
