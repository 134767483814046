import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  /**
   *  function for set local storage.
   * 
   * @param key 
   * @param value 
   */
  setStorage(key: string, value: any) {
    value = btoa(value)
    sessionStorage.setItem(key, value)
  }

  /**
   * function for get local storage.
   * 
   * @param key 
   */
  getStorage(key: string) {
    let value = sessionStorage.getItem(key)
    value = value ? atob(value) : value
    return value
  }

  clearAll() {
    sessionStorage.clear();
  }

}
