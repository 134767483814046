export const LIVE_SERVICE_NAMES = {
    // CR APIs Search
    getChangeRequestFilterValues: 'getChangeRequestFilterValues',
    getChangeRequestSearchGrid: 'getChangeRequestSearchGrid',
    getApplicationPeriodList: 'getApplicationPeriodList',

    //CR APIs Details
    getChangeRequestDetails: 'getChangeRequestDetails',
    getChangeRequestActivity: 'getChangeRequestActivity',
    getChangeRequestNotes: 'getChangeRequestNotes',
    getRejectReasonDropdownValues: 'getRejectReasonDropdownValues',
    getUpdateOutletIvAllocationCRDetails: 'getUpdateOutletIvAllocationCRDetails',
    getUpdateOutletObiAllocationCRDetails: 'getUpdateOutletObiAllocationCRDetails',
    getAssignUserDropdownValues: 'getAssignUserDropdownValues',
    assignCRToSoUser: 'assignCRToSoUser',

    //CR APIs submit
    createChangeRequest: 'createChangeRequest',
    //CR APIs approve/reject
    approveChangeRequest: 'approveChangeRequest',
    rejectChangeRequest: 'rejectChangeRequest',
    forceRejectChangeRequest: 'forceRejectChangeRequest',

    // Oulet APIs Search
    getStateDropDownValues: 'getStateDropDownValues',
    getOutletSearchGrid: 'getOutletSearchGrid',
    getOutletDetails: 'getOutletDetails',
    getGeographyExportUnitsGrid: 'getGeographyExportUnitsGrid',
    getOutletExportDetailsGrid: 'getOutletExportDetailsGrid',
    getTimePeriodOutletIvAllocation: 'getTimePeriodOutletIvAllocation',
    getTimePeriodOutletObiAllocation: 'getTimePeriodOutletObiAllocation',
    getOutletIvAllocation: 'getOutletIvAllocation',
    getOutletObiAllocation: 'getOutletObiAllocation',
    getOutletSalesFilters: 'getOutletSalesFilters',
    getOutletSales: 'getOutletSales',
    getOutletPendingCRs: 'getOutletPendingCRs',

    //Shared APIs
    getUserDetails: 'getUserInfo',
    fetchDocumentDetails: 'getDocuments',
    fetchHelpPageDetails: 'getPrimaryContact',
    uploadDocument: 'uploadDocument',
    fetchDocuments: 'fetchDocuments',
    removeDocument: 'removeDocument',
    getGeographyMap: 'getGeographyMap',
    getHistoryOfChanges: 'getHistoryOfChanges',
    sendDeveloperErrorMail: 'sendDeveloperErrorMail'
};


