import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from './shared.service';
import { ToasterService } from './toaster.service';

import { ErrorPageComponent } from '../components/error-page/error-page.component';

import { API_SUCCESS_STATUS, SOMETHING_WENT_WRONG } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  private modalService: NgbModal

  constructor(
    private injector: Injector,
    private sharedService: SharedService,
    private toasterService: ToasterService
  ) { }

  handleError(error: Error): void {
    console.trace(error);
    this.sharedService.sendDeveloperErrorMail({ 'error': error.stack.toString() }).subscribe(res => {
      if (res.status === API_SUCCESS_STATUS) {
        this.modalService = this.injector.get(NgbModal);
        this.modalService.open(ErrorPageComponent);
      }
      else {
        let toast = {
          message: SOMETHING_WENT_WRONG,
          type: "error",
          show: true
        }
        setTimeout(() => {
          this.toasterService.sendToasterMessage(toast);
        });
      }
    }, (error) => {
      let toast = {
        message: SOMETHING_WENT_WRONG,
        type: "error",
        show: true
      }
      setTimeout(() => {
        this.toasterService.sendToasterMessage(toast);
      });
    });
  }
}