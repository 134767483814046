import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_SUCCESS_STATUS } from 'src/app/shared/utils/constants';
import { LIVE_SERVICE_NAMES } from 'src/app/shared/utils/api-endpoints';
import { environment } from 'src/environments/environment';

import { HttpService } from 'src/app/shared/services/http.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpService
  ) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let payload = {};

    return this.http.postMethod(environment.serverUrl + LIVE_SERVICE_NAMES.getUserDetails, payload).pipe(map(res => {
      if (res.status === API_SUCCESS_STATUS) {
        this.localStorageService.setStorage('userDetails', JSON.stringify(res.result.userDetails))
        this.localStorageService.setStorage('portalConfig', JSON.stringify(res.result.portalConfig))
        return true;
      } else {
        window.location.href = environment.idmLogInUrl;
        this.localStorageService.setStorage('userDetails', null);
        this.localStorageService.setStorage('portalConfig', null);
        return false;
      }
    }, error => {
      console.trace(error);
      window.location.href = environment.idmLogInUrl;
      this.localStorageService.setStorage('userDetails', null);
      this.localStorageService.setStorage('portalConfig', null);
      return false;
    }));
  }
}
