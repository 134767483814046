import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';

import * as variables from 'src/app/shared/utils/api-endpoints';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class HttpService {

    public monitoring = {
        pendingRequestsNumber: 0
    };

    constructor(
        private http: HttpClient
    ) {

    }
    getMethod(url: string): Observable<any> {
        try {
            if (url) {
                this.showSpinner();
                return this.http.get(url).pipe(
                    map(resp => this.handleResponse(resp)),
                    catchError((err) => this.handleError(err)), finalize(() => {
                        this.hideSpinner();
                    })
                );
            }
            else {
                return EMPTY
            }
        } catch (error) {
        }
    }
    postMethod(url: string, payload: any): Observable<any> {
        try {
            if (url) {
                this.showSpinner()
                return this.http.post(url, payload).pipe(
                    map(resp => this.handleResponse(resp)),
                    catchError((err) => this.handleError(err)), finalize(() => {
                        this.hideSpinner()
                    })
                );
            }
            else {
                return EMPTY
            }
        } catch (error) {
        }
    }


    /** handle error */
    handleError(error) {
        if (error.status === 500 || error.status === 0) {
        }
        console.trace(error)
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        if (error.status === 401) {
            return EMPTY;
        } else {
            return throwError(errorMessage);
        }
    }

    handleResponse(resp) {
        if (resp) {
            return resp;
        }
    }
    showSpinner() {
        this.monitoring.pendingRequestsNumber++;
    }
    hideSpinner() {
        this.monitoring.pendingRequestsNumber--;
        if (this.monitoring.pendingRequestsNumber === 0) {
        }
    }

    // check environment and fetch data accordingly
    checkEnvironment(url: string, method: string, payload: Object, header: Object): Observable<any> {
        if (payload && Object.keys(payload).length) {
            payload = this.convertToString(payload)
        }
        switch (environment.env) {
            // if environment is DEV
            case 'DEV': {
                if (method === 'GET') {
                    return this.getMethod(url);
                } else if (method === 'POST') {
                    url = environment.serverUrl + variables.LIVE_SERVICE_NAMES[url];
                    return this.postMethod(url, payload);
                } else {
                    return this.getMethod(url);
                }
            }
            // if environment is QA
            case 'QA': {
                url = environment.serverUrl + variables.LIVE_SERVICE_NAMES[url];
                if (method === 'GET') {
                    return this.getMethod(url);
                } else if (method === 'POST') {
                    return this.postMethod(url, payload);
                } else {
                    return this.getMethod(url);
                }
            }
            // if environment is STAGE
            // tslint:disable-next-line: no-switch-case-fall-through
            case 'STAGE': {
                url = environment.serverUrl + variables.LIVE_SERVICE_NAMES[url];
                if (method === 'GET') {
                    return this.getMethod(url);
                } else if (method === 'POST') {
                    return this.postMethod(url, payload);
                } else {
                    return this.getMethod(url);
                }
            }
            // if environment is PROD
            case 'PROD': {
                url = environment.serverUrl + variables.LIVE_SERVICE_NAMES[url];
                if (method === 'GET') {
                    return this.getMethod(url);
                } else if (method === 'POST') {
                    return this.postMethod(url, payload);
                } else {
                    return this.getMethod(url);
                }
            }
            default: {

            }
        }
    }

    convertToString(payload) {
        try {
            Object.keys(payload).forEach(item => {
                if (typeof payload[item] === 'object') {
                    return this.convertToString(payload[item]);
                }

                payload[item] = payload[item] === null || payload[item] === undefined ? '' : payload[item];
                payload[item] = '' + payload[item];
                payload[item] = payload[item] === '(All)' ? payload[item] : payload[item].toUpperCase();
            });

            return payload;

        } catch (error) {
            console.trace(error);
        }
    }
}



